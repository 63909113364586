import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeleteDialogComponent } from '../dialogs/delete/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PortfolioService } from '../shared/services/portfolios.service';
import { LocalesService } from '../shared/services/locales.service';
import { TimezonesService } from '../shared/services/timezones.service';
import { Timezone } from '../shared/model/timezone.model';
import { ResourceTypeService } from '../shared/services/resource-types.service';
import { ResourceType } from '../shared/model/resourceType.model';
import { Locale, LocaleBaseConfig } from '../shared/model/locale.model';
import { Portfolio } from '../shared/model/portfolio';
import { CookieService } from '../shared/services/cookie.service';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { Program } from '../shared/model/program';
import { Registration } from '../shared/model/registration';
import { Status } from '../shared/model/status.model';
import { StatusesService } from '../shared/services/statuses.service';
import { TelemetryPointService } from 'src/app/shared/services/telemetry-point.service';
import { TelemetryPoint } from '../shared/model/telemetry-point.model';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { SupportingDataService } from 'src/app/shared/services/supporting-data.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss', '../shared/shared.styles.scss'],
})
export class ViewComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  portfolioId = '';
  subscriptions = [];
  locales: Locale[];
  timezones: Timezone[];
  statuses: Status[];
  registrationsList: Registration[] = [];
  loadingPortfolio = false;

  portfolio: Portfolio = {
    id: '',
    displayLabel: '',
    defaultLocale: '',
    timezone: '',
    status: '',
    description: '',
    descriptions: {},
    programId: '',
    registrations: [],
    effectiveFrom: '',
    effectiveTo: '',
    groupId: '',
    discontinueDttm: '',
    displayLabels: {},
    externalReferenceId: '',
    alternateIds: {},
    marketConf: {
      reserveRate: null,
      rampRate: null,
      resourceType: null,
      areaName: null,
      offerPrice: null,
      minStableOutput: null,
    },
  };
  program: Program;
  CONFLICT = 'There is a data conflict with this Portfolio';
  readonly mode = 'view';
  resourceTypes: ResourceType[] = [];
  private _points: TelemetryPoint[] = [];
  loadingDataProviders = true;
  dataProviders: any[];
  org = '';
  reportingIntervals: any[];
  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [new Locale()],
    locales: [new Locale()],
    defaultLocale: new Locale(),
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };
  userLocale = 'en_US';

  get points(): TelemetryPoint[] {
    return this._points;
  }

  get hasPoints() {
    return Boolean(this._points && this._points.find(p => !p.deleted));
  }

  constructor(
    private localesService: LocalesService,
    private timezonesService: TimezonesService,
    private cookieService: CookieService,
    private portfolioService: PortfolioService,
    private router: Router,
    private messageService: GlobalAlertService,
    private translateService: TranslateService,
    private statusesService: StatusesService,
    private resourceTypeService: ResourceTypeService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private telemetryPointService: TelemetryPointService,
    iconRegistry: MatIconRegistry,
    private matIconRegistry: MatIconRegistry,
    private orgSelectorService: ContextSelectorService,
    private ngxCookieService: NgxCookieService,
    private domSanitizer: DomSanitizer,
    private supportingDataService: SupportingDataService,
  ) {
    this.matIconRegistry.addSvgIcon(
      'noData',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/no-data.svg'),
    );
    this.matIconRegistry.addSvgIcon(
        'general-info',
        this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/general-info.svg'),
    )
    this.translateService.get('portfolio.notification.updated_successfully').subscribe((result: string) => {
      this.CONFLICT = this.translateService.instant('portfolio.notification.conflict');
    });
    this.org = this.ngxCookieService.getCookie('preferred-org-id') || '';
    this.timezones = timezonesService.timezones;
    this.statuses = statusesService.statuses;
    this.userLocale = this.ngxCookieService.getCookie('locale') || 'en_US';
    this.localesService.locales$.subscribe(locales => {
      this.locales = locales;
      const locale = this.localesService.checkForLocale(this.userLocale, locales) || locales[0];
      this.multiLocaleConfig.defaultLocale = locale;
    });
    this.loadDynamicLists();

    const regSub = this.portfolioService.registrations$.subscribe(registrations => {
      this.registrationsList = registrations;
    });
    const errSub = this.portfolioService.conflictError.subscribe(() => {
      this.messageService.setError(this.CONFLICT, 7000);
    });
    this.subscriptions.push(...[regSub, errSub]);

    const contextSub = this.orgSelectorService.currentContext$.subscribe(async (orgs: Context[]) => {
      //Hardcode organization id
      // const org = orgs[0];
      let org = {
        id: '974a7baf-9da1-4e1b-ae45-fe148abb77ce',
      };
      this.org = org.id;
      if (org.id) {
        this.loadingDataProviders = true;
        await this.supportingDataService.setDataProviders(org.id);
        this.loadingDataProviders = false;
      }
    });

    const dataProvidersSub = this.supportingDataService.dataProviders$.subscribe(async dataProviders => {
      if (dataProviders) {
        this.dataProviders = dataProviders;
      }
    });

    const reportingIntervalsSub = this.supportingDataService.reportingIntervals$.subscribe(reportingIntervals => {
      reportingIntervals.sort((a: any, b: any) => {
        if (parseInt(a.id, 10) < parseInt(b.id, 10)) {
          return -1;
        } else if (parseInt(a.id, 10) > parseInt(b.id, 10)) {
          return 1;
        } else {
          return 0;
        }
      });

      this.reportingIntervals = reportingIntervals;
    });

    this.subscriptions.push(...[contextSub, dataProvidersSub, reportingIntervalsSub]);
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({});

    const routeSub = this.route.params.subscribe(async params => {
      if (params.id) {
        this.portfolioId = params.id;
        this.loadingPortfolio = true;
        this.portfolioService.selectedPortfolioId$.next(this.portfolioId);
        const portfolio = await this.portfolioService.getPortfolio(params.id);
        if (this.portfolioService.selectedProgram$.value.id !== portfolio.programId) {
          this.portfolioService.selectProgram(portfolio.programId);
        }
        this.loadingPortfolio = false;
      }
    });

    const portfolioSub = this.portfolioService.portfolio$.subscribe(async portfolio => {
      if (portfolio) {
        this.portfolio = portfolio;
        this._points = await this.telemetryPointService.getPointsForPortfolio(portfolio.id);
      }
    });

    const programSub = this.portfolioService.selectedProgram$.subscribe(async program => {
      if (program) {
        this.program = program;
      }
    });

    this.subscriptions.push(...[routeSub, portfolioSub, programSub]);

    const resourceTypesSub = this.resourceTypeService.resourceTypes$.subscribe(resourceTypes => {
      this.resourceTypes = resourceTypes;
    });

    this.subscriptions.push(...[routeSub, portfolioSub, programSub, resourceTypesSub]);
    this.statusesService.setStatuses();
  }

  get locale() {
    return this.cookieService.getLocale();
  }

  getStatusForPortfolio() {
    if (this.statuses && this.statuses.length > 0) {
      return (
        this.statuses.find(status => status.code === this.portfolio.status)?.displayLabel || new Status().displayLabel
      );
    }

    return new Status();
  }

  getLocaleDisplayLabel(): string {
    if (this.locales && this.locales.length > 0 && this.portfolio && this.portfolio.defaultLocale) {
      return this.locales.find(locale => locale.localeName === this.portfolio.defaultLocale).displayLabel;
    }
    return '-';
  }

  getTimezoneDisplayLabel(): string {
    if (this.timezones.length > 0 && this.portfolio && this.portfolio.timezone) {
      return this.timezones.find(timezone => timezone.timezoneName === this.portfolio.timezone).displayLabel;
    }
    return '-';
  }

  getRegistrationData(id, field) {
    if (this.registrationsList.length > 0) {
      const reg = this.registrationsList.find(registration => {
        return registration.id === id;
      });
      if (reg && reg.hasOwnProperty(field)) {
        return reg[field];
      }
    }
  }

  getResourceTypeDisplayLabel(): string {
    if (this.resourceTypes.length > 0 && this.portfolio && this.portfolio.marketConf.resourceType) {
      return this.resourceTypes.find(resourceType => resourceType.code === this.portfolio.marketConf.resourceType)
        .displayLabel;
    }
    return '-';
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  handleCancel() {
    this.router.navigate(['list']);
  }

  openDeleteDialog(): void {
    this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: {
        portfolioId: this.portfolioId,
      },
    });
  }

  handleEdit() {
    this.router.navigate([`details/${this.portfolioId}/edit`]);
  }

  private async loadDynamicLists() {
    this.localesService.setLocales();
    this.timezonesService.setTimezones();
    this.resourceTypeService.setResourceTypes();
  }
}
